import React, {FC, useEffect, useState} from "react";
import {api} from "../api";
import {Card, Col, Row} from "react-bootstrap";
import damen from "../Overview/mannschaftsfoto.jpg";

interface Post {
    title:string;
    subtitle: string;
    description: string;
}

interface Props {
    name: string;
    league: string;
    image: string;
    route: string;
}

export const Team: FC<Props> = ({name, league, image, route}) => {
    const [posts, setPosts] = useState<Post[]>();

    useEffect(()=> {
        api.get(`/${route}/posts`)
            .then((response) => {
                console.log(response);
                setPosts(response.data);
            }).catch((e) => {throw e})
    }, [])

    useEffect(() => {
        console.log(posts);
    }, [posts])

    return (
        <>
            <Row>
                <Col xl={6} lg={4} md={4}>
                    <Row>
                        <Col className="p-4">
                            <Card className="shadow-lg rounded-0 coloration shadow">
                                <Card.Img variant="top" src={image}/>
                                <Card.Body style={{textAlign: 'center'}}>
                                    <Card.Title>{name}</Card.Title>
                                    <Card.Subtitle className="mb-2 coloration">{league}</Card.Subtitle>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={6} lg={12} md={12} sm={6} xs={6} className="p-4">
                            <Card className="shadow-lg rounded-0 coloration shadow">
                                <Card.Body style={{textAlign: 'center'}}>
                                    <Card.Title>News</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={6} lg={12} md={12} sm={6} xs={6} className="p-4">
                            <Card className="shadow-lg rounded-0 coloration clickable">
                                <Card.Body style={{textAlign: 'center'}}>
                                    <Card.Title>Trainer</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={6} lg={12} md={12} sm={6} xs={6} className="p-4">
                            <Card className="shadow-lg rounded-0 coloration clickable">
                                <Card.Body style={{textAlign: 'center'}}>
                                    <Card.Title>Kader</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={6} lg={12} md={12} sm={6} xs={6} className="p-4">
                            <Card className="shadow-lg rounded-0 coloration clickable">
                                <Card.Body style={{textAlign: 'center'}}>
                                    <Card.Title>Tabelle</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xl={6} lg={8} md={8}>
                    {posts !== undefined && posts.map((post) => {
                        return (
                            <Row>
                                <Col className="p-4">
                                    <Card className="shadow-lg rounded-0 coloration shadow">
                                        <Card.Body style={{textAlign: 'center'}}>
                                            <Card.Title>{post.title}</Card.Title>
                                            <Card.Subtitle className="mb-2 coloration">{post.subtitle}</Card.Subtitle>
                                            <Card.Text>
                                                {post.description}
                                            </Card.Text>
                                            <Card.Footer>
                                                <a>Fussball.de | </a>
                                                <a>Details | </a>
                                                <a>Instagram | </a>
                                                <a>Facebook</a>
                                            </Card.Footer>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        )
                    })}
                </Col>
            </Row>
        </>
    )
}