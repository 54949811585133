import React, {FC, ReactElement, useState} from "react";
import {Container, Image, Nav, Navbar} from "react-bootstrap";
import {useMatch} from "react-router-dom";
import fcMan from '../FC_WAPPEN_Vektorgrafik.svg';
import {api} from "../api";

export const Header: FC = (): ReactElement => {
    const match = useMatch({path: '/', end: true, caseSensitive: true});
    const [status, setStatus] = useState<boolean>(false);

    api.get("/health")
        .then((response) => {
            if (response.data === "Backend available" && !status) {
                setStatus(true);
            }
        }).catch((e) => {throw e});

    return (
        <Navbar>
            <Container fluid>
                <Navbar.Brand href="#home" className="coloration p-0">
                    {!match && (
                        <Image
                            src={fcMan} height={40}
                            className="d-inline-block align-top"
                        />
                    )}
                </Navbar.Brand>
                <Nav>
                    <Nav.Link active={status} disabled={!status}>
                        <i className="bi-activity"/>
                    </Nav.Link>
                    <Nav.Link active>
                        <i className="bi-instagram coloration"/>
                    </Nav.Link>
                    <Nav.Link active>
                        <i className="bi-facebook coloration"/>
                    </Nav.Link>
                    <Nav.Link active>
                        <i className="bi-telephone-inbound coloration"/>
                    </Nav.Link>
                    <Nav.Link active>
                        <i className="bi-at coloration"/>
                    </Nav.Link>
                    <Nav.Link active>
                        <i className="bi-geo coloration"/>
                    </Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
}
