import React, {FC, ReactElement, useEffect, useState} from "react";
import {Card, Col, Row, Table} from "react-bootstrap";
import './vorstand.scss';
import emma from './vorstand/BockFrank.jpg';
import ollo from './vorstand/OliverSpaniol.jpg';
import unknown from './vorstand/neu-temp/unknown.jpg';
import toni from './vorstand/StephanKriesten.jpeg';
import {api} from "../api";

interface ImageCardProps {
    image: string;
    title: string;
    name: string;
    since: number;
}

const ImageCard: FC<ImageCardProps> = ({image, title, name, since}): ReactElement => {
    return (
        <Card className="text-center shadow-lg rounded-0 coloration shadow">
            <Card.Img variant="top" src={image}/>
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>
                    <div>{name}</div>
                    <div><i className="p-1 bi-clock-history"/> {since}</div>
                </Card.Text>
            </Card.Body>
            <Card.Footer>
                <i className="p-2 bi-at" style={{fontSize: '26px'}}/>
                <i className="p-2 bi-telephone-inbound" style={{fontSize: '26px'}}/>
            </Card.Footer>
        </Card>
    )
}

interface TitleProps {
    title: string;
}

const Title: FC<TitleProps> = ({title}): ReactElement => {
    return (
        <Row className="justify-content-md-center">
            <Col>
                <Card className="text-center shadow-lg rounded-0 coloration shadow">
                    <Card.Body>
                        <h3>{title}</h3>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

interface FBM {
    id: number;
    year: string;
    firstChairman: string;
    secondChairman: string;
    cashier: string;
    sportsDirector: string;
    youthLeader: string;
    secretary: string;
}

export const Vorstand: FC = (): ReactElement => {
    const [fbms, setFBMs] = useState<FBM[]>();

    useEffect(()=> {
        api.get(`/boardMembers/formers`)
            .then((response) => {
                console.log(response);
                setFBMs(response.data);
            }).catch((e) => {throw e})
    }, [])

    return (
        <>
            <Title title={'Vorstand'}/>
            <Row className="justify-content-md-center">
                <Col xl={2} lg={3} md={4} sm={6} className="p-4">
                    <ImageCard image={emma} title={'1.Vorsitzender'} name={'Frank Bock'} since={2006}/>
                </Col>
                <Col xl={2} lg={3} md={4} sm={6} className="p-4">
                    <ImageCard image={unknown} title={'2.Vorsitzende'} name={'Meike Fricke'} since={2021}/>
                </Col>
                <Col xl={2} lg={3} md={4} sm={6} className="p-4">
                    <ImageCard image={ollo} title={'Kassierer'} name={'Oliver Spaniol'} since={2006}/>
                </Col>
                <Col xl={2} lg={3} md={4} sm={6} className="p-4">
                    <ImageCard image={unknown} title={'Sportleiter'} name={'Ingo Zornhagen'} since={2021}/>
                </Col>
                <Col xl={2} lg={3} md={4} sm={6} className="p-4">
                    <ImageCard image={unknown} title={'Jugendleiter'} name={'Dominik Weidner'} since={2022}/>
                </Col>
                <Col xl={2} lg={3} md={4} sm={6} className="p-4">
                    <ImageCard image={unknown} title={'Schriftführer'} name={'Lars Maibaum'} since={2021}/>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xl={4} lg={6} md={8} sm={12}>
                    <Title title={'Beisitzer'}/>
                    <Row>
                        <Col sm={6} className="p-4">
                            <ImageCard image={toni} title={'Sozialwart'} name={'Stephan Kriesten'} since={2006}/>
                        </Col>
                        <Col sm={6} className="p-4">
                            <ImageCard image={unknown} title={'Dart'} name={'Sven Schäning'} since={2022}/>
                        </Col>
                    </Row>
                </Col>
                <Col xl={2} lg={3} md={4} sm={6}>
                    <Title title={'Unterstützer'}/>
                    <Row className="justify-content-md-center">
                        <Col md={12} className="p-4">
                            <ImageCard image={unknown} title={'Platzkassiererin'} name={'Gabi Behring'} since={2018}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Title title={'Historie'}/>
                <Row>
                    <Col className="p-4">
                        <Card className="text-center shadow-lg rounded-0 coloration shadow">
                            <Table responsive bordered className="coloration">
                                <thead>
                                <tr>
                                    <th>Jahr</th>
                                    <th>1.Vorsitz</th>
                                    <th>2.Vorsitz</th>
                                    <th>Kasse</th>
                                    <th>Sportleitung</th>
                                    <th>Jugendleitung</th>
                                    <th>Schriftführung</th>
                                </tr>
                                </thead>
                                <tbody>
                                {fbms !== undefined && fbms.map((fbm) => {
                                    return (
                                        <tr>
                                            <td>{fbm.year}</td>
                                            <td>{fbm.firstChairman}</td>
                                            <td>{fbm.secondChairman}</td>
                                            <td>{fbm.cashier}</td>
                                            <td>{fbm.sportsDirector}</td>
                                            <td>{fbm.youthLeader}</td>
                                            <td>{fbm.secretary}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
            </Row>
        </>
    );
}
