import React from 'react';
import './App.css';
import {Container} from "react-bootstrap";
import {Header} from "./Header";
import {Overview} from "./Overview";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Vorstand} from "./Vorstand";
import {Damen} from "./Damen";
import {Team} from "./Team";
import erste from "./Overview/manni21.jpeg";
import damen from "./Overview/mannschaftsfoto.jpg";
import zweite from "./Overview/mannschaftsfotoZWOTE.jpg";
import senioren from "./Overview/mannschaftsfoto.jpeg";

function App() {
    return (
        <div style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'rgba(255,255,255,0.25)',
        }}>
            <BrowserRouter>
                <Header/>
                <Container fluid>
                    <Routes>
                        <Route path="/" element={<Overview/>}/>
                        <Route path="/vorstand" element={<Vorstand/>}/>
                        <Route path="/fussball/team/damen" element={<Team name={'Damen'} league={'Bezirksliga'} route={'damen'} image={damen}/>}/>
                        <Route path="/fussball/team/herren" element={<Team name={'Herren'} league={'Nordharzliga'} route={'herren'} image={erste}/>}/>
                        <Route path="/fussball/team/senioren" element={<Team name={'Senioren'} league={'Nordharzliga'} route={'senioren'} image={senioren}/>}/>
                        <Route path="/darts/team" element={<Team name={'Darts'} league={''} route={'darts'} image={zweite}/>}/>
                    </Routes>
                </Container>
            </BrowserRouter>
        </div>
    );
}

export default App;
